import customersObj from '../../config'

export const hardcodedDataOnPage = {
  tracfone: {
    pagesData: {
      home: {
        description: "We're here to help you set up and get your new phone working",
      },
      welcome: {
        userPhrase: "What's this?",
        botPhrase_1:
          "This is a <span>BADGE</span>. Every time you learn and master a new skill, you'll earn a new badge.",
        botPhrase_2:
          "Earning badges is easy. All you need to do is complete a full skills section like you just did, and you'll get your reward.",
        botPhrase_3:
          'You can locate all of your badges and complete skills sections by going to the <span>MAIN MENU</span>.',
      },
      explore: {
        title: 'You’re phone is ready to use.',
        description: 'It’s time for you to learn more about ',
        steps: [
          {
            title: 'Tracking your benefits',
            description:
              "Download Tracfone's My Account app to keep track of all your benefits.",
          },
          {
            title: 'Continuous service',
            description:
              "Enroll in Auto-Refill now and we'll take care of the rest.",
          },
          {
            title: 'Call Abroad',
            description:
              'Need to make an international call but want to save? Learn about our Global Calling Card.',
          },
          {
            title: '...And much more',
            description: '',
          },
        ],
      },
      chattyIntroduction: {
        title: 'Need help at any moment?',
        description:
          `<p>Introducing <span style='color: #C74100;font: normal normal bold 14px/19px Josefin Sans;text-transform: uppercase;'>${customersObj.tracfone.botName}</span>, our automated bot that is excited to help you with any question. </p><p>Just tap on it and you will be able to solve your doubts.</p>`,
      },
      learningCurve: {
        title: 'My Learning Curve',
        description: 'Discover what is next and how to get this knowledge',
        levels: [
          {
            icon: 'Expert.svg',
            title: 'Expert',
            badgeText: 'The mysteries of Tracfone have unveiled in front of you. Your knowledge and emphasis on discovery can only be matched by your ability to provide wisdom to others.',
          },
          {
            icon: 'Pro.svg',
            title: 'Pro',
            badgeText:
              'A strong, resilient being who looks for knowledge and welcome challenges. It is only a matter of time before you evolve.',
          },
          {
            icon: 'Halfway.svg',
            title: 'Halfway',
            badgeText:
              'It is not only about the path. Instead, it is the discovery of mysteries what keeps this being going on challenging journeys.',
          },
          {
            icon: 'Rookie.svg',
            title: 'Rookie',
            badgeText:
              'A curious natural being who is attracted to study the environment without directly interacting with it.',
          },
          {
            icon: 'Initiate.svg',
            title: 'Initiate',
            badgeText:
              'A brave being who step into the future with excitement and certainty that the right decision was taken.',
          },
        ],
      },
      protect: {
        title: 'Device Protection Plan',
        description:
          'Getting a new phone is an exciting moment, but accidents do happen.',
        steps: [
          {
            title: 'Mechanical and electrical coverage',
            description: 'Drops. Spills. Mechanical malfunctions.',
          },
          {
            title: 'Lost and stolen coverage',
            description:
              'With our Protect Your Phone program, we have your back. There are different options to choose from, some even covering lost and stolen devices.',
          },
          {
            title: 'One-time or monthly payment',
            description:
              'Check out our Protect Your Phone program and enroll today.',
          },
        ],
      },
      registration: {
        title: "Let's get to know <br/>each other better.",
      },
      mainMenu: {
        title: 'Ready to start learning?',
        description:
          'Select the tutorial you need and we will guide you through the journey.',
      },
      badges: {
        title: 'My Badges',
        description:
          "Be proud of this wealth of knowledge you have achieved and check what's missing",
      },
      tutorialComplete: {
        title: 'Great job!',
      },
      discover: {
        title: "Let's discover Tracfone",
        description:
          'Discover other amazing opportunities and learn about everything you need to gain.',
        links: [
          {
            title: 'Tracking minutes?',
            body: 'Check My Account APP & track everything!',
            url: 'https://app.tracfone.com',
          },
          {
            title: 'Continuous service',
            body: 'No need to track your Service End with this',
            url: 'https://www.tracfone.com/autorefill',
          },
          {
            title: 'Play to win & save',
            body: 'Like free things? We do too... Check this',
            url: 'https://www.tracfone.com/rewards',
          },
          {
            title: 'Call abroad!',
            body: 'International calls? We have your back',
            url: 'https://extras.tracfone.com/en/ild',
            isNew: true,
          },
          {
            title: 'Missing something?',
            body: 'Cables, cases and more to enjoy your phone',
            url: 'https://shop.tracfone.com/shop/en/tracfonestore/accessories',
          },
        ],
      },
    },
    badges: [
      {
        type: 'badges',
        slug: 'setting-up-your-phone',
        title: 'Setting up your phone',
        description:
          'You mastered the setting up of your phone. Help others to move forward with confidence. Your new phone is ready for you.',
        link: '/badges/5773ba52-ee52-4a5e-a3ae-f408652a2e7e',
        icon_name: 'SetupBadge.svg',
        fileId: '440a9ff3-aac4-4c19-8db5-a651c2bfdb2d',
      },
      {
        type: 'badges',
        slug: 'using-your-phone',
        title: 'Using your phone',
        description:
          "Technology doesn't have anything on you. You have mastered the usage of your new phone and with it comes great responsibility.",
        link: '/badges/64deef47-ac29-4d92-a7f2-f2ab0cfeb6c1',
        icon_name: 'TechBadge.svg',
        fileId: '1d9dcf5a-ed41-4750-813d-8e752d6f63b8',
      },
      {
        type: 'welcome',
        slug: 'welcome-to-the-family',
        title: 'Welcome to the family',
        description:
          'We are happy to have you onboard and be part of our family. For this and many years more.',
        link: '',
        icon_name: 'WelcomeBadge.svg',
        fileId: 'd8e66f86-6107-4401-b89d-ed98be9e07fc',
      },
      {
        type: 'badges',
        slug: 'shielded',
        title: 'Shielded',
        description:
          'Safety and protection are key. Discover how to protect your phone today against multiple risks.',
        link: '/protect',
        icon_name: 'ShieldedBadge.svg',
        fileId: '41e9216c-d89a-49cb-84c5-9214adc1b715',
      },
      {
        type: 'badges',
        slug: 'exploring',
        title: 'Exploring',
        description:
          'Discover mystic relics and travel around all Tracfone World. The stakes and rewards are high.',
        link: '/discover',
        icon_name: 'ExploringBadge.svg',
        fileId: '25f95e40-3c33-45f0-994b-b3385646f49e',
      },
    ],
  },
  straighttalk: {
    pagesData: {
      home: {
        description: "We're here to help you set up and get your new phone working",
      },
      welcome: {
        userPhrase: "What's this?",
        botPhrase_1:
          "This is a <span>BADGE</span>. Every time you learn and master a new skill, you'll earn a new badge.",
        botPhrase_2:
          "Earning badges is easy. All you need to do is complete a full skills section like you just did, and you'll get your reward.",
        botPhrase_3:
          'You can locate all of your badges and complete skills sections by going to the <span>MAIN MENU</span>.',
      },
      explore: {
        title: 'You’re phone is ready to use.',
        description: 'It’s time for you to learn more about ',
        steps: [
          {
            title: 'Tracking your benefits',
            description:
              "Download Straight Talk's My Account app to keep track of all your benefits.",
          },
          {
            title: 'Continuous service',
            description:
              "Enroll in Auto-Refill now and we'll take care of the rest.",
          },
          {
            title: 'Call Abroad',
            description:
              'Need to make an international call but want to save? Learn about our Global Calling Card.',
          },
          {
            title: '...And much more',
            description: '',
          },
        ],
      },
      chattyIntroduction: {
        title: 'Need help at any moment?',
        description:
          `<p>Introducing <span style='color: #c64427;font: normal normal bold 14px/19px Josefin Sans;text-transform: uppercase;'>${customersObj.straighttalk.botName}</span>, our automated bot that is excited to help you with any question. </p><p>Just tap on it and you will be able to solve your doubts.</p>`,
      },
      learningCurve: {
        title: 'My Learning Curve',
        description: 'Discover what is next and how to get this knowledge',
        levels: [
          {
            icon: 'Expert.svg',
            title: 'Expert',
            badgeText: 'The mysteries of Straight Talk have unveiled in front of you. Your knowledge and emphasis on discovery can only be matched by your ability to provide wisdom to others.',
          },
          {
            icon: 'Pro.svg',
            title: 'Pro',
            badgeText:
              'A strong, resilient being who looks for knowledge and welcome challenges. It is only a matter of time before you evolve.',
          },
          {
            icon: 'Halfway.svg',
            title: 'Halfway',
            badgeText:
              'It is not only about the path. Instead, it is the discovery of mysteries what keeps this being going on challenging journeys.',
          },
          {
            icon: 'Rookie.svg',
            title: 'Rookie',
            badgeText:
              'A curious natural being who is attracted to study the environment without directly interacting with it.',
          },
          {
            icon: 'Initiate.svg',
            title: 'Initiate',
            badgeText:
              'A brave being who step into the future with excitement and certainty that the right decision was taken.',
          },
        ],
      },
      protect: {
        title: 'Device Protection Plan',
        description:
          'Getting a new phone is an exciting moment, but accidents do happen.',
        steps: [
          {
            title: 'Mechanical and electrical coverage',
            description: 'Drops. Spills. Mechanical malfunctions.',
          },
          {
            title: 'Lost and stolen coverage',
            description:
              'With our Protect Your Phone program, we have your back. There are different options to choose from, some even covering lost and stolen devices.',
          },
          {
            title: 'One-time or monthly payment',
            description:
              'Check out our Protect Your Phone program and enroll today.',
          },
        ],
      },
      registration: {
        title: "Let's get to know <br/>each other better.",
      },
      mainMenu: {
        title: 'Ready to start learning?',
        description:
          'Select the tutorial you need and we will guide you through the journey.',
      },
      badges: {
        title: 'My Badges',
        description:
          "Be proud of this wealth of knowledge you have achieved and check what's missing",
      },
      tutorialComplete: {
        title: 'Great job!',
      },
      discover: {
        title: "Let's discover Straight Talk",
        description:
          'Discover other amazing opportunities and learn about everything you need to gain.',
        links: [
          {
            title: 'Tracking minutes?',
            body: 'Check My Account APP & track everything!',
            url: 'https://app.straighttalk.com/?_ga=2.140083761.380103055.1649159906-1104239340.1637683767',
          },
          {
            title: 'Continuous service',
            body: 'No need to track your Service End with this',
            url: 'https://www.straighttalk.com/refill/auto-refill',
          },
          {
            title: 'Play to win & save',
            body: 'Like free things? We do too... Check this',
            url: 'https://rewards.straighttalk.com/',
          },
          {
            title: 'Call abroad!',
            body: 'International calls? We have your back',
            url: 'https://extras.straighttalk.com/en/ild',
            isNew: true,
          },
          {
            title: 'Missing something?',
            body: 'Cables, cases and more to enjoy your phone',
            url: 'https://www.straighttalk.com/accessories',
          },
        ],
      },
    },
    badges: [
      {
        type: 'badges',
        slug: 'setting-up-your-phone',
        title: 'Setting up your phone',
        description:
          'You mastered the setting up of your phone. Help others to move forward with confidence. Your new phone is ready for you.',
        link: '/badges/5773ba52-ee52-4a5e-a3ae-f408652a2e7e',
        icon_name: 'SetupBadge.svg',
        fileId: '440a9ff3-aac4-4c19-8db5-a651c2bfdb2d',
      },
      {
        type: 'badges',
        slug: 'using-your-phone',
        title: 'Using your phone',
        description:
          "Technology doesn't have anything on you. You have mastered the usage of your new phone and with it comes great responsibility.",
        link: '/badges/64deef47-ac29-4d92-a7f2-f2ab0cfeb6c1',
        icon_name: 'TechBadge.svg',
        fileId: '1d9dcf5a-ed41-4750-813d-8e752d6f63b8',
      },
      {
        type: 'welcome',
        slug: 'welcome-to-the-family',
        title: 'Welcome to the family',
        description:
          'We are happy to have you onboard and be part of our family. For this and many years more.',
        link: '',
        icon_name: 'WelcomeBadge.svg',
        fileId: 'd8e66f86-6107-4401-b89d-ed98be9e07fc',
      },
      {
        type: 'badges',
        slug: 'shielded',
        title: 'Shielded',
        description:
          'Safety and protection are key. Discover how to protect your phone today against multiple risks.',
        link: '/protect',
        icon_name: 'ShieldedBadge.svg',
        fileId: '41e9216c-d89a-49cb-84c5-9214adc1b715',
      },
      {
        type: 'badges',
        slug: 'exploring',
        title: 'Exploring',
        description:
          'Discover mystic relics and travel around all Straight Talk World. The stakes and rewards are high.',
        link: '/discover',
        icon_name: 'ExploringBadge.svg',
        fileId: '25f95e40-3c33-45f0-994b-b3385646f49e',
      },
    ],
  },
}
