import React, { useRef, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'

const PhoneContainer = styled.div`
  cursor: pointer;
  position: relative;
`

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
`

const PhoneFrame = styled.img`
  position: relative;
  z-index: 2;
  transform: ${({ transform }) => `rotate(${transform}deg)`};
  left: ${({ left }) => left}px;
  transform-origin: top left;
`

const ScreenPhoneImage = styled.img`
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  transform: ${({ transform }) => `rotate(${transform}deg)`};
  position: absolute;
  transform-origin: left top;
  z-index: 1;
`

const TouchButtonContainer = styled.div`
  cursor: pointer;
  width: 32px;
  height: 32px;
  position: absolute;
  border-radius: 100%;
  z-index: 3;
  transition: 0.3s all ease;

  left: ${({ left }) => left}px;
  top: ${({ top }) => top}px;
  opacity: ${({ left }) => (left ? '1' : '0')};
  animation: ${({ animation }) => css`
    ${animation} infinite 1s linear
  `};
  box-shadow: ${({ boxShadow }) => css`
    ${boxShadow}
  `};
  transform: ${({ transform }) => `rotate(${transform}deg)`};

  button {
    cursor: pointer;
    background-color: rgba(69, 133, 49, 0.8);
    border: 2px solid white;
    height: 32px;
    width: 32px;
    position: relative;
    top: 0;
    left: 0;
    outline-offset: 2px;
    border-radius: 100%;
    box-shadow: 5px 5px 10px 0 #0000006b;
  }
`

const keyframesUp = keyframes`
from { transform: translateX(0px) translateY(0px); opacity: 0.4 }
to { transform: translateX(0px) translateY(-70px); opacity: 1 }
`
const keyframesDown = keyframes`
from { transform: translateX(0px) translateY(0px) }
to { transform: translateX(0px) translateY(70px) }
`
const keyframesRight = keyframes`
from { transform: translateX(0px) translateY(0px) }
to { transform: translateX(70px) translateY(0px) }
`
const keyframesLeft = keyframes`
from { transform: translateX(0px) translateY(0px) }
to { transform: translateX(-70px) translateY(0px) }
`

const TutorialImage = ({ nextSubstep, currentStep, isMobile }) => {
  const isLandscape = currentStep.orientation === 'landscape'
  const { phoneScreenImage: borderPhone, buttonScreenImages } = currentStep
  const {
    screenYUpperLeft, screenXUpperLeft, screenYSize, screenXSize,
  } = borderPhone

  const buttonXPos = currentStep.action?.on_screen
    ? buttonScreenImages[0].buttonXUpperLeft + borderPhone?.screenXUpperLeft
    : buttonScreenImages[0].buttonXUpperLeft

  const buttonYPos = currentStep.action?.on_screen
    ? buttonScreenImages[0].buttonYUpperLeft + borderPhone?.screenYUpperLeft
    : buttonScreenImages[0].buttonYUpperLeft

  const imageUrl = currentStep.tutorialstep.imageLocation

  const getAnimationInfo = () => {
    if ((isLandscape && isMobile)) {
      switch (currentStep.action?.type) {
        case 'swipe_up':
          return keyframesRight
        case 'swipe_down':
          return keyframesLeft
        case 'swipe_left':
          return keyframesUp
        case 'swipe_right':
          return keyframesDown
        default:
          return ''
      }
    }
    switch (currentStep.action?.type) {
      case 'swipe_up':
        return keyframesUp
      case 'swipe_down':
        return keyframesDown
      case 'swipe_left':
        return keyframesLeft
      case 'swipe_right':
        return keyframesRight
      default:
        return ''
    }
  }

  const getBoxShadowAnimation = () => {
    if (isLandscape && isMobile) {
      switch (currentStep.action?.type) {
        case 'swipe_left':
          return 'rgba(0, 0, 0, 0.8) 0px 10px 20px 0px, rgba(0, 0, 0, 0.8) 0px 30px 20px 0px, rgba(0, 0, 0, 0.8) 0px 50px 20px 0px, rgba(0, 0, 0, 0.8) 0px 70px 20px 0px'
        case 'swipe_right':
          return 'rgba(0, 0, 0, 0.8) 0px -10px 20px 0px, rgba(0, 0, 0, 0.8) 0px -30px 20px 0px, rgba(0, 0, 0, 0.8) 0px -50px 20px 0px, rgba(0, 0, 0, 0.8) 0px -70px 20px 0px'
        case 'swipe_down':
          return 'rgba(0, 0, 0, 0.8) 10px 0px 20px 0px, rgba(0, 0, 0, 0.8) 30px 0px 20px 0px, rgba(0, 0, 0, 0.8) 50px 0px 20px 0px, rgba(0, 0, 0, 0.8) 0px 0px 20px 0px'
        case 'swipe_up':
          return 'rgba(0, 0, 0, 0.8) -10px 0px 20px 0px, rgba(0, 0, 0, 0.8) -30px 0px 20px 0px, rgba(0, 0, 0, 0.8) -50px 0px 20px 0px, rgba(0, 0, 0, 0.8) -70px 0px 20px 0px'
        default:
          return 'none'
      }
    }
    switch (currentStep.action?.type) {
      case 'swipe_up':
        return 'rgba(0, 0, 0, 0.8) 0px 10px 20px 0px, rgba(0, 0, 0, 0.8) 0px 30px 20px 0px, rgba(0, 0, 0, 0.8) 0px 50px 20px 0px, rgba(0, 0, 0, 0.8) 0px 70px 20px 0px'
      case 'swipe_down':
        return 'rgba(0, 0, 0, 0.8) 0px -10px 20px 0px, rgba(0, 0, 0, 0.8) 0px -30px 20px 0px, rgba(0, 0, 0, 0.8) 0px -50px 20px 0px, rgba(0, 0, 0, 0.8) 0px -70px 20px 0px'
      case 'swipe_left':
        return 'rgba(0, 0, 0, 0.8) 10px 0px 20px 0px, rgba(0, 0, 0, 0.8) 30px 0px 20px 0px, rgba(0, 0, 0, 0.8) 50px 0px 20px 0px, rgba(0, 0, 0, 0.8) 0px 0px 20px 0px'
      case 'swipe_right':
        return 'rgba(0, 0, 0, 0.8) -10px 0px 20px 0px, rgba(0, 0, 0, 0.8) -30px 0px 20px 0px, rgba(0, 0, 0, 0.8) -50px 0px 20px 0px, rgba(0, 0, 0, 0.8) -70px 0px 20px 0px'
      default:
        return 'none'
    }
  }

  const calculateImageTopPosition = () => {
    // Such difficult conditions are due to the fact that when
    // landscape is in mobile mode, the picture should not take
    // a horizontal position, because the content will not fit.
    if (isLandscape) {
      if (isMobile) return screenXUpperLeft
      return screenYUpperLeft + screenYSize
    }
    return screenYUpperLeft
  }

  return (
    <PhoneContainer
      onClick={nextSubstep}
    >
      <ImageWrapper
        height={isMobile && isLandscape ? `${screenXSize + 2 * screenXUpperLeft}px` : 'auto'}
        width={isMobile && isLandscape ? `${screenYSize + 2 * screenYUpperLeft}px` : 'auto'}
      >
        <PhoneFrame
          src={currentStep.phoneScreenImage.imageLocation}
          transform={(isLandscape && isMobile) ? 90 : 0}
          left={(isLandscape && isMobile) ? (2 * screenYUpperLeft) + screenYSize : 0}
          alt=""
          aria-hidden
        />
        <ScreenPhoneImage
          alt="Current phone"
          loading="lazy"
          src={imageUrl}
          top={calculateImageTopPosition()}
          left={(isLandscape && isMobile) ? screenYUpperLeft : screenXUpperLeft}
          height={isLandscape ? screenXSize : screenYSize}
          width={isLandscape ? screenYSize : screenXSize}
          transform={isLandscape && !isMobile ? -90 : 0}
        />
      </ImageWrapper>
      {currentStep?.action.type !== 'automate' && (
        <TouchButtonContainer
          left={(isLandscape && isMobile) ? buttonYPos : buttonXPos}
          top={(isLandscape && isMobile) ? buttonXPos : buttonYPos}
          animation={getAnimationInfo()}
          boxShadow={getBoxShadowAnimation()}
        >
          <button tabIndex="0" aria-hidden="true" />
        </TouchButtonContainer>
      )}
    </PhoneContainer>
  )
}

export default TutorialImage
