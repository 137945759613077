import parsePhoneNumber from 'libphonenumber-js'

import { fetchGetJson } from './services/fetch-get-json'
import { fetchContentApi } from './services/content-api'
import { getParam } from './helpers/parameters'
import { fetchPost } from './services/fetchers'
import { addApiKey } from './services/add-api-key'
import { getCustomer } from './helpers/utils'
import { captureException } from '@sentry/react'

const customer = `wc-${getCustomer()}`

/*
  ----------------------
  Tutorials
  ----------------------
*/

export const fetchTutorialStepsApi = (tutorialId) => fetchContentApi(`/public/v1/tutorial/by-serial/${tutorialId}?customer=${customer}&language=en`)

export const fetchTutorialListApi = (referenceId) => fetchContentApi(`/tutorial?product_reference_id=${referenceId}&language=en&customer=${customer}`)

export const fetchDeviceBySerial = (serial) => fetchContentApi(`/public/v1/product/by-serial/${serial}?language=en&customer=${customer}`)

/*
  ----------------------
  Gamify
  ----------------------
*/

export const fetchCreateCustomerApi = async (phone) => {
  const formattedPhone = formatPhoneNumber(phone)
  const url = await getParam('gamifyApiUrl')
  const path = `${url}/customers`
  const body = JSON.stringify({ phone: formattedPhone })

  try {
    const response = await fetchPost(path, '', body)
    return response
  } catch (error) {
    captureException(error)
    if (error.status && error.status === 400) {
      return fetchGetCustomerApi(formattedPhone)
    }
    console.log(error.message)
  }
  return null
}

export const fetchCompleteTaskApi = async (badgeId, customerId, task) => {
  if (!customerId) {
    console.warn('Skipping fetchCompleteTaskApi: no customerId')
    return Promise.resolve()
  }

  const url = await getParam('gamifyApiUrl')
  const path = `${url}/badges/${badgeId}/tasks?customerId=${customerId}`
  const body = JSON.stringify(task)

  return fetchPost(path, '', body)
}

export const fetchGetCustomerApi = async (phone) => {
  const url = await getParam('gamifyApiUrl')
  const path = `${url}/customers/${phone}`

  return fetchGetJson(path, addApiKey())
}

export const fetchGetTasksApi = async (badgeId, customerId) => {
  const url = await getParam('gamifyApiUrl')
  const path = `/badges/${badgeId}/tasks`
  const query = customerId
    ? `?customerId=${customerId}`
    : ''

  return fetchGetJson(`${url}${path}${query}`, addApiKey())
}

export const fetchGetBadgesApi = async (customerId) => {
  const url = await getParam('gamifyApiUrl')
  const path = `${url}/badges`
  const query = customerId ? `customerId=${customerId}` : ''

  return fetchGetJson(`${path}?${query}`, addApiKey())
}

export const fetchBadgesWithTasks = async ({ customerId }) => {
  const badges = await fetchGetBadgesApi(customerId)
  const tasks = await Promise.all(badges.map((badge) => (
    fetchGetTasksApi(badge.id, customerId)
  )))

  return badges.filter(({ description }) => description.match(/Exploring|Welcome|Shielded/ig)).map((badge, i) => {
    const badgeTasks = tasks[i]
    const completedTasks = badgeTasks.filter((task) => task.isDone)

    const progress = Math.round((completedTasks.length / badgeTasks.length) * 100)
    return {
      ...badge,
      progress,
      tasks: badgeTasks,
    }
  })
}

export const fetchGetLevels = async (customerId) => {
  const query = customerId ? `customerId=${customerId}` : ''
  const url = await getParam('gamifyApiUrl')
  const path = `${url}/levels`

  return fetchGetJson(`${path}?${query}`, addApiKey())
}

export const fetchUpdateCustomer = async ({ customerId, customerData }) => {
  const url = await getParam('gamifyApiUrl')
  const path = `${url}/updateCustomer/${customerId}`

  const body = JSON.stringify(customerData)

  return fetchPost(path, '', body)
}

export const fetchGetFullCustomer = async (customerId) => {
  const url = await getParam('gamifyApiUrl')
  const path = `${url}/getFullCustomer/${customerId}`

  return fetchGetJson(path, addApiKey())
}

export const fetchSubscriberApi = async ({ subscriberId }) => {
  const url = await getParam('gamifyApiUrl')
  return fetchGetJson(`${url}/subscriber/${subscriberId}`, addApiKey())
}

export const fetchDeleteCustomer = async (customerId) => {
  const url = await getParam('gamifyApiUrl')
  const path = `${url}/customer/${customerId}`

  return fetchGetJson(path, addApiKey({ method: 'DELETE' }))
}

export const fetchBadgeIcon = async ({ fileId, customerId }) => {
  const url = await getParam('gamifyApiUrl')
  const response = await fetch(
    `${url}/files/${fileId}?customerId=${customerId}`,
    addApiKey({
      headers: {
        accept: 'image/png', // Needed for ApiGateway to work
      },
    }),
  )

  const blob = await response.blob()
  return URL.createObjectURL(blob)
}

/*
  ----------------------
  Other
  ----------------------
*/

const formatPhoneNumber = (str) => {
  const { number } = parsePhoneNumber(str, 'US')
  return number
}

export const fetchDeviceApi = async ({ referenceId }) => {
  const [url, academyApiToken] = await Promise.all([
    getParam('gamifyApiUrl'),
    getParam('academyApiToken'),
  ])
  const kmsApiBase = await getParam('kmsApiBase')
  const response = await fetchGetJson(
    `${kmsApiBase}/public/v1/product/by-reference/${referenceId}?customer=${customer}&language=en`,
    addApiKey({
      headers: {
        Authorization: `Bearer ${academyApiToken}`,
      },
    }),
  )

  if (!response.product.serial) {
    throw new Error(`device not found ${referenceId}`)
  }

  return response
}

export const fetchCategories = async ({ referenceId }) => {
  try {
    const kmsApiBase = await getParam('kmsApiBase')
    const response = await fetch(
      `${kmsApiBase}/public/v1/wc/category?customer=${customer}&reference_id=${referenceId}`,
      addApiKey({
        method: 'GET',
      }),
    )
    const responseJson = await response.json()

    return responseJson
      .map((category) => ({
        ...category,
        wcTutorials: category.wcTutorials.sort(
          (a, b) => a.output_order - b.output_order,
        ),
      }))
      .sort((a, b) => a.output_order - b.output_order)
  } catch (error) {
    throw new Error(`Error getting categories: ${error}`)
  }
}
