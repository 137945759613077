import React, { useContext, useEffect, useMemo } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { useConfigContext, useLocalStorageState } from '../../hooks'
import LearningBadge from '../../components/LearningBadge'
import Header from '../../components/Header'
import PageSwitcher from '../../components/PageSwitcher'
import Onboarding from '../../components/Onboarding'
import Shielded from '../../assets/Shielded.svg'
import { findClosestProgressValue } from '../../constants/badgeProgress'
import Overlay from '../../components/Overlay'
import BlueBadge from '../../components/BlueBadge'
import { BadgesCtx } from '../../BadgesCtx'
import useImage from '../../hooks/useImage'
import { hardcodedDataOnPage } from '../../constants/assets/data'
import { getParam } from '../../helpers/parameters'

const Wrapper = styled.div`
  background: #ffffff;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  overflow-y: ${({ showOnboarding }) => (showOnboarding ? 'hidden' : 'auto')};
`

const Body = styled.div`
  margin-bottom: ${({ showOnboarding }) => (showOnboarding ? '0px' : '100px')};

  @media (max-width: 767px) {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: ${({ showOnboarding }) => (showOnboarding ? '0px' : '137px')};
  }
`

const Info = styled.p`
  color: #373737;
  font-size: 14px;
  padding-bottom: 56px;
  width: 80%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;

  h1 {
    font-weight: 700;
    font-size: 22px;
    color: #313334;
    margin-top: 16px;
  }

  h2 {
    font-size: 14px;
    margin: 0 auto;
    color: #373737;
    font-weight: normal;
  }

  @media (min-width: 300px) and (max-width: 767px) {
    padding-bottom: 30px;
  }
`

const BadgesWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
`

const MainMenu = () => {
  const badgeCtx = useContext(BadgesCtx)
  const { categories } = useContext(BadgesCtx)
  const assetsUrl = getParam('assetsUrl')
  const [showOnboarding, setShowOnboarding] = useLocalStorageState(
    'showOnboarding',
    true,
  )
  const { customerConfig } = useConfigContext()
  const [showOverlay, setShowOverlay] = useLocalStorageState('showWTF', false)
  const [protectVisited] = useLocalStorageState('protectVisited', false)
  const pagesData = useMemo(
    () => hardcodedDataOnPage[customerConfig.customer].pagesData.mainMenu,
    [],
  )
  const { image } = useImage(
    hardcodedDataOnPage[customerConfig.customer].badges?.find((i) => i.type === 'welcome')
      ?.icon_name,
  )

  window.setShowOnboarding = setShowOnboarding

  useMemo(() => {
    if (!showOnboarding) {
      setShowOverlay(false)
    }
  }, [showOnboarding])

  useEffect(() => {
    if (showOverlay) {
      document.querySelector('.App').setAttribute('aria-hidden', true)
    } else document.querySelector('.App').setAttribute('aria-hidden', false)
  }, [showOverlay])

  const currentCategory = useMemo(() => hardcodedDataOnPage[customerConfig.customer].badges.find((i) => i.type === 'welcome'), [])

  const displayCategories = () => (
      <BadgesWrapper className="badges">
        {categories
          && categories.map((category) => (
            <LearningBadge
              key={category.id}
              title={category.name}
              icon={`${assetsUrl}${category.icon}`}
              progress={findClosestProgressValue(
                badgeCtx.getCategoryById(category.id)?.progress || 0,
              )}
              onClick={() => ({ pathname: `/badges/${category.id}` })}
            />
          ))}
        <LearningBadge
          key="protect"
          title="Device Protection Plan"
          icon={Shielded}
          progress={protectVisited ? 100 : 0}
          onClick={() => ({ pathname: '/protect' })}
        />
      </BadgesWrapper>
  )

  return (
    <>
      {!showOnboarding &&
        showOverlay &&
        createPortal(
          <Overlay
            onClick={() => {
              setShowOverlay(false)
            }}
          >
            <BlueBadge
              title={currentCategory?.title}
              linkTitle="Access section"
              onClose={() => {
                setShowOverlay(false)
              }}
              text={currentCategory?.description}
              icon={image}
            />
          </Overlay>,
          document.body,
        )}
      <Wrapper
        showOnboarding={showOnboarding}
        aria-hidden={showOverlay}
        className="main-menu-page"
      >
        {showOnboarding && (
          <Onboarding
            enabled={showOnboarding}
            onLaunch={() => {
              setShowOverlay(false)
            }}
            onFinish={() => {
              setShowOnboarding(false)
              setShowOverlay(true)
            }}
          />
        )}
        <Header pageName={'Main Menu'} inverted={true} />
        <PageSwitcher
          navLeft="/learning-curve"
          navRight="/explore-more"
          pageTitle="Main menu"
          pageNumber={1}
        />
        <Body as='main' id="main-content" showOnboarding={showOnboarding}>
          <Info>
            <h1>{pagesData.title}</h1>
            <h2>{pagesData.description}</h2>
          </Info>
          {displayCategories()}
        </Body>
      </Wrapper>
    </>
  )
}

export default MainMenu
