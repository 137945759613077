import { getParam } from './helpers/parameters'

const instanceEnvironment = (() => getParam('instanceEnvironment'))()

const customers = {
  tracfone: {
    retailerLink: 'https://tracfone.com',
    protectPhoneLink: 'https://www.tracfone.com/protectyourphones/landing',
    customerModalName: 'Tracfone',
    faqLink: 'https://support.tracfone.com/devicepicker',
    contactUsLink: 'https://www.tracfone.com/contactus',
    deLink: 'https://tracfone.saveonyourwirelessbill.com',
    myAccountLink: 'https://www.tracfone.com/createaclogin',
    botCustomer: 'tracfone',
    botName: 'Automated Assistant',
    chatBotLink: instanceEnvironment === 'prod' ? 'https://chat-widget-assets.tracapis.com/tracfone/widget.js'
      : 'https://chat-widget-assets.dev.tracapis.com/tracfone/widget.js',
    widgetConfig: instanceEnvironment === 'prod' ? 'https://chat-widget-assets.tracapis.com/configs/tracfone.json' : 'https://chat-widget-assets.dev.tracapis.com/configs/tracfone.json',
    welcomeBadgeId: '43d2f029-bab6-470c-8c44-17a8f7368102',
    gtmId: 'UA-186193746-1',
    customer: 'tracfone',
    defaultDeviceReferenceId: 'TFSAS102DC',
  },
  straighttalk: {
    retailerLink: 'https://www.straighttalk.com',
    protectPhoneLink: 'https://www.straighttalk.com/handset-protection/mobile-protect',
    customerModalName: 'Straight Talk',
    deLink: 'https://straighttalk.saveonyourwirelessbill.com',
    faqLink: 'https://support.straighttalk.com/devicepicker',
    contactUsLink: 'https://www.straighttalk.com/support/',
    myAccountLink: 'https://www.straighttalk.com/shop/login',
    botCustomer: 'stacademy',
    botName: 'Automated Assistant',
    chatBotLink: instanceEnvironment === 'prod' ? 'https://chat-widget-assets.tracapis.com/straighttalk/widget.js'
      : 'https://chat-widget-assets.dev.tracapis.com/straighttalk/widget.js',
    widgetConfig: process.env.instanceEnvironment === 'prod' ? 'https://chat-widget-assets.tracapis.com/configs/straighttalk.json' : 'https://chat-widget-assets.dev.tracapis.com/configs/straighttalk.json',
    welcomeBadgeId: '43d2f029-bab6-470c-8c44-17a8f7368102',
    gtmId: 'UA-186193746-1',
    customer: 'straighttalk',
    defaultDeviceReferenceId: 'STSAS102DC',
  },
}

const customersObj = Object.entries(customers).reduce((acc, [brand, value]) => {
  acc[brand] = value
  acc[`${brand}-preprod`] = {
    ...value,
    contentCustomerId: `${value.contentCustomerId}-preprod`,
  }
  return acc
}, {})

export default customersObj
